import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/affilate_mono_repo_vilkencykel/gatsby/src/components/mdxLayout.js";
import { graphql, Link } from "gatsby";
import ProductsDisplay from "../../components/productDisplay.js";
import CategoriesLinks from "../../components/categoriesLinks.js";
export const query = graphql`
  query {
    allMongodbStoreProducts(sort: {fields: popularity_score, order: DESC}, filter: {brand: { eq: "rock machine" }}) {
      edges {
        node {
          	brand
			full_name
			id
			local_image_name
			model
			original_prices
			popularity_score
			prices
			product_id
			similair_products
			show_on_pages
			sub_product_type
			store
			url
			engine
			last_updated_date
			derived_properties {
				motor_type
				average_range_km
				number_of_gears
				battery_capacity
				frame_type
			}
			fields {
				brand_url
				full_name
				full_name_url
				model_url
			}
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "rock-machine-elcyklar--kraft-komfort-och-innovation"
    }}>{`Rock Machine Elcyklar – Kraft, Komfort och Innovation`}</h1>
    <p>{`Välkommen till vår samlingssida för Rock Machine elcyklar! Här hittar du ett brett urval av högkvalitativa elcyklar som kombinerar avancerad teknik, kraft och komfort. Rock Machine är kända för att erbjuda robusta och pålitliga cyklar som klarar av både urbana miljöer och utmanande terräng.`}</p>
    <h2 {...{
      "id": "rock-machine-elcykel-serien"
    }}>{`Rock Machine Elcykel Serien`}</h2>
    <h3 {...{
      "id": "rock-machine-torrent-inte50"
    }}>{`Rock Machine Torrent INTE50`}</h3>
    <p>{`Denna serie är designad för dem som söker en kraftfull och pålitlig cykelupplevelse. `}<strong parentName="p">{`Rock Machine Torrent INTE50-29 B Dam`}</strong>{` är ett perfekt val för kvinnor som vill ha en elcykel med extra kraft och lång räckvidd. Utrustad med en högpresterande Bosch-motor och ett kraftfullt 500Wh batteri, kan Torrent INTE50-29 B Dam ta dig upp till 100 km på en enda laddning. Det robusta aluminiumramen och de 29-tums hjulen garanterar en smidig och stabil färd över både asfalt och lätta terränger. Dessutom erbjuder den dämpade luftgaffeln och den 9-växlade Shimano Altus-drivlinan en extra komfort och effektivitet. Hydrauliska skivbromsar säkerställer att du alltid kan stanna säkert, oavsett förhållanden.`}</p>
    <h3 {...{
      "id": "rock-machine-catherine"
    }}>{`Rock Machine Catherine`}</h3>
    <p>{`För den som vill ha det bästa av två världar, erbjuder `}<strong parentName="p">{`Rock Machine Catherine E70-29`}</strong>{` en perfekt kombination av kraft, komfort och mångsidighet. Med en effektiv dämpad framgaffel och kvalitativ aluminiumram, är denna elcykel idealisk för både skogsstigar och stadsmiljö. Catherine E70-29 är utrustad med en stark mittmotor och välbalanserade 29-tumshjul, vilket ger en kraftfull elcykelupplevelse med en imponerande räckvidd på 100 km. Den pålitliga 8-växlade drivlinan och robusta hydrauliska skivbromsar förstärker din cykeltur ytterligare, oavsett terräng.`}</p>
    <h2 {...{
      "id": "köpguide-välja-rätt-rock-machine-elcykel"
    }}>{`Köpguide: Välja Rätt Rock Machine Elcykel`}</h2>
    <p>{`Att välja rätt elcykel handlar om att identifiera dina behov och matcha dem med cykelns specifikationer:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`För långdistansåkare`}</strong>{`: Om du är ute efter att åka långa distanser eller använda cykeln för pendling, är `}<strong parentName="li">{`Rock Machine Torrent INTE50`}</strong>{` det perfekta valet, tack vare dess kraftfulla motor och imponerande räckvidd.`}</li>
      <li parentName="ul"><strong parentName="li">{`För mångsidig användning`}</strong>{`: Om du vill ha en elcykel som klarar både stadsmiljö och off-road, är `}<strong parentName="li">{`Rock Machine Catherine`}</strong>{` det optimala valet. Denna serie erbjuder en fantastisk mix av kraft, komfort och mångsidighet som passar den moderna cyklisten.`}</li>
    </ul>
    <p>{`Oavsett vilken modell du väljer, kan du vara säker på att få en högkvalitativ cykel som är byggd för att hålla. Rock Machine elcyklar är konstruerade för att erbjuda en överlägsen cykelupplevelse, oavsett var ditt nästa äventyr tar dig.`}</p>
    <ProductsDisplay data={props.data.allMongodbStoreProducts} location={props.location} mdxType="ProductsDisplay"></ProductsDisplay>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      